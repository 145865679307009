<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="578px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}用户信息</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="姓名" prop="name" class="w50 mr16">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="性别" prop="genderDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.genderDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'genderDictionaryItemId')"
				>
					<el-option v-for="(item, index) in sexDictionaryItemList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="出生日期" prop="birthDate" class="w50 mr16">
				<el-date-picker value-format="yyyy-MM-dd" v-model.trim="ruleForm.birthDate" :disabled="isShowDetail" type="date" placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="民族" prop="ethnicityDictionaryItemId" class="w50 mr0">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.ethnicityDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'ethnicityDictionaryItemId')"
				>
					<el-option v-for="(item, index) in nationList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="电话" prop="phoneNumber" class="w50 mr16">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="身份证" prop="identificationCode" class="w50 mr0">
				<el-input v-model.trim="ruleForm.identificationCode" maxlength="18" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="家庭地址" prop="homeAddress">
				<el-input v-model.trim="ruleForm.homeAddress" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="户籍地址" prop="hukouAddress">
				<el-input v-model.trim="ruleForm.hukouAddress" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="addressClass clearfix" label="居住地址区域">
				<el-form-item v-for="(item, index) in addressList" :key="index" :class="item.className">
					<el-input v-model.trim="item.default" disabled></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item label="详细地址" class="attractionAddress" prop="address">
				<el-input
					v-model.trim="ruleForm.address"
					disabled
					placeholder="请选择详细地址"
					:style="{ width: isShowDetail ? '100%' : 'calc(100% - 74px)' }"
				></el-input>
				<el-button type="text" @click="chooseAddress" v-if="!isShowDetail">选择地址</el-button>
			</el-form-item>
			<el-form-item label="乡镇/街道" v-if="ruleForm.address != ''">
				<el-select disabled v-model="ruleForm.townCode" placeholder="请选择" @change="changeTown($event)">
					<el-option v-for="(item, index) in townList" :key="index" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="村" prop="villageCode" v-if="ruleForm.address != ''">
				<el-select :disabled="isShowDetail" v-model="ruleForm.villageCode" placeholder="请选择" @change="changeSelect($event, 'villageCode')">
					<el-option v-for="(item, index) in villageList" :key="index" :label="item.title" :value="item.code"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
		<choose-address ref="chooseAddress" @changeAddress="changeAddress" />
	</el-dialog>
</template>

<script>
import { verifyPhone, verifyIdCard } from '@/utils/toolsValidate';
export default {
	components: {
		chooseAddress: () => import('../../institutionManager/countyMedicalManager/chooseAddress.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		var checkIdCard = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身份证号'));
			} else if (!verifyIdCard(value)) {
				callback(new Error('请输入正确的身份证号'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			saveLoading: false,
			ruleForm: {
				name: '',
				genderDictionaryItemId: '',
				birthDate: '',
				phoneNumber: '',
				identificationCode: '',
				ethnicityDictionaryItemId: '',
				homeAddress: '',
				hukouAddress: '',
				address: '',
				townCode: '',
				villageCode: '',
			},
			sexDictionaryItemList: [],
			nationList: [],
			addressList: [
				{
					label: '省',
					className: 'float-l addressClass-content',
					prop: 'economize',
					disabled: true,
					default: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[0].title,
				},
				{
					label: '市',
					className: 'float-l addressClass-content',
					prop: 'market',
					disabled: true,
					default: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[1].title,
				},
				{
					label: '区县',
					className: 'float-l addressClass-content mr0',
					prop: 'county',
					disabled: true,
					default: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].title,
				},
			],
			townList: [],
			villageList: [],
			rules: {
				name: [{ required: true, message: '请输入社区编号', trigger: 'blur' }],
				genderDictionaryItemId: [{ required: true, message: '请选择性别', trigger: 'change' }],
				birthDate: [{ required: true, message: '请选择生日', trigger: 'change' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				identificationCode: [{ required: true, validator: checkIdCard, trigger: 'blur' }],
				ethnicityDictionaryItemId: [{ required: true, message: '请输入民族', trigger: 'blur' }],
				homeAddress: [{ required: true, message: '请输入家庭地址', trigger: 'blur' }],
				hukouAddress: [{ required: true, message: '请输入户籍地址', trigger: 'blur' }],
				address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
				villageCode: [{ required: true, message: '请选择村', trigger: 'change' }],
			},
		};
	},
	props: ['isShowDetail'],
	mounted() {},
	methods: {
		init(info) {
			this.createdVisible = true;
			this.link = info.link;
			this.getSexList();
			this.getNationList();
			this.getTown();
			if (info.row) {
				this.$nextTick(() => {
					this.ruleForm = JSON.parse(JSON.stringify(info.row));
					this.ruleForm.townCode = info.row.regions[3].code;
					this.ruleForm.villageCode = info.row.regionCode;
					this.changeTown(this.ruleForm.townCode);
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取性别下拉
		getSexList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'GENDER' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.sexDictionaryItemList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取民族下拉
		getNationList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'NATION' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.nationList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取乡镇/街道list
		getTown() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeTown(code) {
			this.villageList = [];
			if (!code || code == '') return;
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.villageList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 下拉选择
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
		// 选择地址
		chooseAddress() {
			let data = JSON.parse(sessionStorage.getItem('userInfo')) || {};
			if (data.id) {
				this.$refs.chooseAddress.center = [data.longitude || 0, data.latitude || 0];
			}
			this.$refs.chooseAddress.chooseAddressVisible = true;
		},
		// 获取到的地址
		changeAddress(data) {
			const { addressData } = data;
			let townCode = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList[2]?.code || 0;
			let townTitle = JSON.parse(sessionStorage.getItem('userInfo'))?.chinaRegionDtoList[2]?.title || '';
			if (addressData.adcode != townCode) {
				this.$message.error(`请选择${townTitle}的地址`);
				return;
			}
			this.ruleForm.address = addressData.district + addressData.township + addressData.street + addressData.streetNumber;
			this.ruleForm.longitude = data.lng;
			this.ruleForm.latitude = data.lat;
			this.getTownCode({ code: addressData.adcode, title: addressData.township });
		},
		// 查询乡镇/街道code
		getTownCode({ code, title }) {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code, title } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$set(this.ruleForm, 'townCode', res.data.collection[0].code);
						this.changeTown(res.data.collection[0].code);
						this.$forceUpdate();
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						regionCode: this.ruleForm.villageCode,
					};
					let promiseUrl = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					promiseUrl
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
								this.saveLoading = false;
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.addressClass {
		&-content {
			width: calc((100% - 32px) / 3);
			margin-right: 16px;
			margin-bottom: 0;
		}
	}
}
.attractionAddress {
	/deep/ .el-button {
		width: 56px;
		border-bottom: 1px solid #1db9b1;
		margin-left: 16px;
		padding: 0;
		&:hover {
			color: #33a7a1;
			border-bottom: 1px solid #33a7a1;
		}
	}
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>